import Send from '../../trans.client.js'

// 주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //선적확정 처리
  shippingConfirm (formData) {
    return Send({
      url: '/trans/shipping-confirm/' + formData.bkgNo,
      method: 'put'
    })
  }
}
